export default {
  themes: {
    light: {
      primary: '#182F56',
      primary1: '#33486B',
      primary2: '#4E6080',
      secondary: '#697995',
      secondary1: '#8491A9',
      secondary2: '#9FAABE',
      tertiary: '#BAC2D2',
      tertiary1: '#D5DBE7',
      tertiary2: '#EFF3FB',
      accent: '#039BE5',
      warning: '#FBA902',
      info: '#182F56',
      info1: '#4E6080',
      info2: '#4E6080',
      success: '#82b440',
      success1: '#94BF5E',
      success2: '#A0C772',
      success3: '#ACCE85',
      error200: '#e57373',
      error: '#e76b6a',
      error600: '#ec5e5c',
      error800: '#ee5350',
      greyc0: '#c0c0c0',
      greyd0: '#d0d0d0',
      greye0: '#e0e0e0',
      greycc: '#cccccc',
      greydd: '#dddddd',
      greyee: '#eeeeee',
      greyf3: '#f3f3f3',
      greyf6: '#f6f6f6',
      greyf9: '#f9f9f9',
      greyfa: '#fafafa',
      black: '#000000',
      white: '#FFFFFF',
      label: '#999999'
    },
    light2: {
      primary: '#82b440',
      secondary: '#484A4F',
      secondary2: '#42494F',
      accent: '#039BE5',
      warning: '#FBA902',
      info: '#17acc6',
      info1: '#4791AC',
      info2: '#397790',
      success: '#82b440',
      success1: '#94BF5E',
      success2: '#A0C772',
      success3: '#ACCE85',
      error200: '#e57373',
      error: '#e76b6a',
      error600: '#ec5e5c',
      error800: '#ee5350',
      greyc0: '#c0c0c0',
      greyd0: '#d0d0d0',
      greye0: '#e0e0e0',
      greycc: '#cccccc',
      greydd: '#dddddd',
      greyee: '#eeeeee',
      greyf3: '#f3f3f3',
      greyf6: '#f6f6f6',
      greyf9: '#f9f9f9',
      greyfa: '#fafafa',
      black: '#000000',
      white: '#FFFFFF',
      label: '#999999'
    },
    dark: {
      primary: '#82b440',
      secondary: '#484A4F',
      secondary2: '#42494F',
      accent: '#039BE5',
      warning: '#FBA902',
      info: '#17acc6',
      info1: '#4791AC',
      info2: '#397790',
      success: '#82b440',
      success1: '#94BF5E',
      success2: '#A0C772',
      success3: '#ACCE85',
      error200: '#e57373',
      error: '#e76b6a',
      error600: '#ec5e5c',
      error800: '#ee5350',
      greyc0: '#c0c0c0',
      greyd0: '#d0d0d0',
      greye0: '#e0e0e0',
      greycc: '#cccccc',
      greydd: '#dddddd',
      greyee: '#eeeeee',
      greyf3: '#f3f3f3',
      greyf6: '#f6f6f6',
      greyf9: '#f9f9f9',
      greyfa: '#fafafa',
      black: '#000000',
      white: '#FFFFFF',
      label: '#999999'
    }
  }
}
