<template>
  <div>
    <v-container
      class="ma-0 pa-0 primary header_container"
      fluid
    />
    <div
      class="okaml_logo"
    >
      <div
        class="okaml_logo_inner"
      >
        <v-img
          :src="require('@/assets/okaml.svg')"
          class="okaml_logo_image"
          height="34"
          max-width="120"
          contain
        />
        <div
          style="position: absolute; top: -70px; width: 100%; max-width: 1200px; height: 60px;"
        >
          <div
            class="pa-1"
            style="position: relative; top: 16px;"
          >
            <div
              class="ma-0 pa-0 d-inline-flex"
              style="min-width: 160px;"
            >
              <v-icon
                dark
                small
                class="ma-0 pa-0"
              >
                fa-phone
              </v-icon>
              <v-img
                alt="tel"
                max-height="16"
                max-width="130  "
                contain
                class="ma-0 pa-0"
                :src="require('@/assets/okaml_tel.png')"
              />
            </div>
            <div
              class="ma-0 pa-0 d-inline-flex"
              style="min-width: 180px;"
            >
              <v-icon
                dark
                small
                class="ma-0 pa-0"
              >
                fa fa-envelope-o
              </v-icon>
              <v-img
                alt="email"
                max-height="16"
                max-width="210"
                contain
                :src="require('@/assets/okaml_email.png')"
              />
            </div>

            <div
              class="ma-0 pa-0 d-inline-flex float-right"
              style="margin-right:16px;"
            >
              <app-right-menu
                :hide-text-sx="false"
                class="mr-4"
              />
            </div>
            <div
              class="ma-0 mr-4 pa-0 d-inline-flex float-right"
            >
              <a
                v-if="$route.path !== '/login'"
                title="Login"
                class="okaml_link ma-0 pa-0"
                @click.stop="$router.push('/login').catch(() => {})"
              ><i class="fa fa-user pa-0 px-1" />{{ $i18n.t('$l.app.login') }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginNavigation',
  props: {
    navActiveButton: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style lang="scss" scoped>
  .header_container {
    height: 120px;
  }
  .okaml_logo {
    position: absolute;
    top: 84px;
    height: 72px;
    width: 100%;
  }
  .okaml_logo_inner {
    height: 100%;
    max-width: 1200px;
    background-color: white;
    border-radius: 50px;
    margin: auto;
  }
  .okaml_logo_image {
    position: absolute;
    margin-top: 20px;
    margin-left: 54px;
  }
  .okaml_link {
    color: white !important;
    text-decoration: none;
    font-size: 14px;
    line-height:22px;
    font-weight: 400;
    letter-spacing: 1px;
  }

</style>
