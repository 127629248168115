<template>
  <div>
    <transition
      mode="out-in"
      :css="false"
      @before-enter="beforeEnter"
      @enter="enter"
    >
      <div>
        <v-alert
          v-if="alert && $store.state.user.isAuthenticated"
          dense
          type="info"
          class="ma-3 pa-2 body-2 align-center mt-5 mb-0"
          outlined
          tile
        >
          {{ $i18n.t('$l.hint.plannedShutdownSystem') + ' ' + left + '. '+ $i18n.t('$l.hint.automaticLogoutAfterDowntime') }}
        </v-alert>
      </div>
    </transition>
    <v-dialog
      v-model="dialog"
      max-width="550"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $i18n.t('$l.systemalert.title') }}
        </v-card-title>

        <v-card-text v-if="alert">
          {{ $i18n.t('$l.systemalert.popUpHint') + ' '+ $i18n.t('$l.app.from') + ' ' + $utils.formatDate(alert.startDate)+ ' ' + $i18n.t('$l.app.to') + ' ' + $utils.formatDate(alert.endDate) }}
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            {{ $i18n.t('$l.systemalert.understand') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Velocity from 'velocity-animate'

function sec2time (timeInSeconds) {
  var pad = function (num, size) { return ('000' + num).slice(size * -1) }
  var time = parseFloat(timeInSeconds).toFixed(3)
  var hours = Math.floor(time / 60 / 60)
  var minutes = Math.floor(time / 60) % 60
  var seconds = Math.floor(time - minutes * 60)
  return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2)
}

export default {
  name: 'ApiAlert',
  data () {
    return {
      left: '00:00:00',
      alert: null,
      dialog: false,
      agreed: false,
      exp: 0,
      intervals: {}
    }
  },
  created () {
    this.doServerTimeout()
    this.intervals._1 = setInterval(() => {
      this.doServerTimeout()
    }, 60000)
  },
  methods: {
    startInterval () {
      this.intervals._2 = setInterval(() => {
        this.exp = new Date(this.alert.startDate).getTime() - new Date().getTime()
        this.left = sec2time(this.exp / 1000)
        this.doGetLogout()
      }, 1000)
    },

    doServerTimeout () {
      var inHours = 1
      this.$xapi.get('administration/activeSystemAlert?inHours=' + inHours)
        .then(r => {
          this.alert = r.data
        })
        .catch(e => {
        })
        .finally(() => {
          if (this.alert != null) {
            this.exp = new Date(this.alert.startDate).getTime()
            this.startInterval()
            if (!this.agreed) {
              this.dialog = true
              this.agreed = true
            }
          }
        })
    },
    doGetLogout () {
      if (this.exp < 0) {
        if (this.$store.state.user.isAuthenticated) {
          try {
            clearInterval(this.intervals._1)
            clearInterval(this.intervals._2)
          } catch {}
          this.$store.dispatch('logoutUser')
        }
      }
    },
    beforeEnter (el) {
      el.style.opacity = 0
    },
    enter (el, done) {
      Velocity(el,
        { opacity: 1 },
        { duration: 400, easing: 'easeIn  ', complete: done })
    }
  }
}
</script>
