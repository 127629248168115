<template>
  <div>
    <v-menu
      v-model="searchResultItemsMenu"
      offset-y
      :close-on-click="false"
      :close-on-content-click="false"
      max-width="350"
      attach="#screeningSearchItemsMenuAnchor"
      nudge-bottom="1"
      :nudge-left="($vuetify.breakpoint.xs && this.$route.path !== '/screening') ? 156 : 0 "
    >
      <template v-slot:activator="{}">
        <div style="display: flex; width: 100%;">
          <input
            v-model="screeningSearchPhrase"
            v-bind="$attrs"
            type="search"
            name="query"
            :placeholder="$i18n.t('$l.screening.searchPersonOrCompany')"
            autocorrect="off"
            autocapitalize="off"
            autocomplete="off"
            spellcheck="false"
            style="outline: none; width: 100%; padding: 5px;"
            @keydown.enter="doSearch"
          >

          <v-progress-circular
            v-if="isLoadingData"
            class="white"
            color="primary"
            :indeterminate="isLoadingData"
            width="4"
            style="width: 24px; height: auto;"
          />

          <v-icon
            class="white primary--text px-1"
            @click="resetScreeningSearch"
          >
            mdi-close
          </v-icon>

          <v-tooltip
            v-model="showSearchTooltip"
            bottom
            close-delay="400"
            :open-on-hover="false"
            :open-on-focus="false"
          >
            <template v-slot:activator="{}">
              <v-icon
                class="white primary--text pr-1"
                @click="showSearchTooltip = !showSearchTooltip"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <div
              style="max-width: 300px;"
            >
              {{ $i18n.t('$l.screening.searchHint') }}
            </div>
          </v-tooltip>
        </div>
      </template>

      <div
        style="max-height: 300px;"
      >
        <ScreeningSearchItems
          :screening-search-items="screeningSearchItems"
          @redirect-to-screening="redirectToScreening"
        />

        <v-list
          class="ma-0 pa-0"
        >
          <v-list-item
            class="pa-0 ma-0 px-1 DML"
            style="background-color: #f0f0f0;"
            @click="redirectToScreening({})"
          >
            <v-list-item-avatar
              class="ma-0 pa-0"
            >
              <v-icon
                class="info1--text"
                large
              >
                mdi-search-web
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-subtitle>
                {{ $i18n.t('$l.screening.notFoundWhatYouExpected') }}
              </v-list-item-subtitle>
              <v-list-item-title
                class="body-1"
              >
                {{ $i18n.t('$l.screening.extendedSearch') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-menu>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

import ScreeningSearchItems from '@/components/screening/ScreeningSearchItems.vue'

export default {
  name: 'SearchAnyPerson',
  components: {
    ScreeningSearchItems
  },
  data () {
    return {
      screeningSearchItems: [],
      isLoadingData: false,
      showSearchTooltip: false
    }
  },
  computed: {
    screeningSearchPhrase: {
      get () {
        return this.$store.getters.getScreeningSearchObject.searchPhrase
      },
      set (val) {
        let clone = JSON.parse(JSON.stringify(this.$store.getters.getScreeningSearchObject))
        clone.searchPhrase = val
        this.$store.commit('SET_SCREENING_SEARCH_OBJECT', clone)
      }
    },
    searchResultItemsMenu () {
      return this.screeningSearchItems && this.screeningSearchItems.length > 0
    }
  },
  watch: {
    screeningSearchPhrase: debounce(function (val) {
      this.screeningSearchItems = []
      if (!val || val === null || val.length < 3) {
        this.screeningSearchItems = []
        return
      }
      this.isLoadingData = true
      this.$xapi.post('endpointsScreeningSearch/businessIdOrName', val)
        .then(r => {
          if (r.data.length < 1) {
            this.screeningSearchItems = [{}]
          } else {
            this.screeningSearchItems = r.data
          }
        })
        .catch(e => {
          this.screeningSearchItems = [{}]
        })
        .finally(() => {
          this.isLoadingData = false
        })
    }, 500)
  },
  methods: {
    redirectToScreening (item) {
      this.$store.commit('SET_SCREENING_SELECTED_ITEM', item)
      this.screeningSearchItems = []
      this.$router.push({ name: 'Screening' }).catch(e => {})
    },
    resetScreeningSearch () {
      this.screeningSearchPhrase = ''
      this.$store.commit('SET_SCREENING_SELECTED_ITEM', {})
    },
    doSearch () {
      if (this.isLoadingData) return
      let val = this.screeningSearchPhrase
      this.screeningSearchItems = []
      if (!val || val === null || val.length < 3) {
        this.screeningSearchItems = []
        return
      }
      this.isLoadingData = true
      this.$xapi.post('endpointsScreeningSearch/businessIdOrName', val)
        .then(r => {
          if (r.data.length < 1) {
            this.screeningSearchItems = [{}]
          } else {
            this.screeningSearchItems = r.data
          }
        })
        .catch(e => {
          this.screeningSearchItems = [{}]
        })
        .finally(() => {
          setTimeout(() => {
            this.isLoadingData = false
          }, 500)
        })
    }
  }
}
</script>
