<template>
  <div>
    <v-footer
      class="greyee justify-center pa-0"
      inset
      app
    >
      <div
        v-if="showCookieAgreement"
        class="pa-3"
        style="width: 100%; border: 5px solid orange;"
      >
        <div class="">
          {{ $i18n.t('$l.app.cookiesInfo') }}
          <span
            class="okaml_cursor_pointer primary--text text--darken-2"
            @click.stop="$router.push('/use-of-cookies').catch(() => {})"
          >
            {{ $i18n.t('$l.app.moreInfo') }}
          </span>
          <v-btn
            class="mt-2 d-flex float-right primary"
            text
            @click="setCookieAgreement()"
          >
            {{ '$l.app.cookieAgree' | translate }}
          </v-btn>
        </div>
      </div>

      <span class="font-weight-light copyright">
        &copy;
        2018 - {{ $utils.getCurrentYear() }}
        <a
          href="https://www.digitalsystems.eu"
          target="_blank"
          class="ds"
        >Digital Systems a.s.</a>
      </span>
      <span
        class="ml-3 body-1 font-weight-light"
      >
        {{ ' OK AML ver. ' + version }}
      </span>
    </v-footer>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      showCookieAgreement: false
    }
  },
  computed: {
    version () {
      return process.env.PACKAGE_VERSION
    }
  },
  mounted () {
    this.checkCookieAgreement()
  },
  methods: {
    refreshPage () {
      location.reload(true)
    },
    setCookieAgreement () {
      this.$cookies.set('cookieAgreement', true, -1)
      this.checkCookieAgreement()
    },
    checkCookieAgreement () {
      let agree = this.$cookies.get('cookieAgreement')
      if (agree && agree === 'true') {
        this.showCookieAgreement = false
      } else {
        this.showCookieAgreement = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .ds {
    font-weight: 400;
    color: #248 !important;
    text-decoration: none;
  }
</style>
