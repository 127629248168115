<template>
  <v-main class="greyf6">
    <app-api-logout />
    <app-app-alert
      v-if="$store.state.user.isAuthenticated"
    />
    <transition
      name="fade"
      mode="out-in"
    >
      <router-view :key="$route.fullPath" />
    </transition>
  </v-main>
</template>

<script>
export default {
  name: 'Content',
  metaInfo () {
    return {
      title: this.$route.name
    }
  }
}
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
