<template>
  <v-app
    id="okaml_app"
  >
    <v-img
      v-if="!$store.state.user.isAuthenticated"
      v-resize="onResize"
      :src="require('@/assets/drawer.jpg')"
      class="okaml_background_image"
      :height="imgh"
      :width="imgw"
    />
    <app-system-bar v-if="$store.state.user.isAuthenticated" />
    <app-app-bar v-if="$store.state.user.isAuthenticated" />
    <app-drawer v-if="$store.state.user.isAuthenticated" />
    <app-content />
    <app-footer />
    <app-version-checker />
  </v-app>
</template>

<script>
import locale from 'locale2'

export default {
  name: 'App',
  data () {
    return {
      imgh: 1000,
      imgw: 1500,
      exp: 0
    }
  },
  metaInfo () {
    return {
      title: 'OKAML',
      titleTemplate: (titleChunk) => {
        return titleChunk ? `OKAML | ${titleChunk}` : 'OKAML'
      }
    }
  },
  created () {
    this.onResize()
    this.$store.dispatch('loadUserGlobalState')
    this.$store.commit('INIT_LOCALE', { locale: locale, ri18n: this.$root.$i18n })
    this.$store.dispatch('loadCountries')
  },
  methods: {
    onResize () {
      this.imgh = window.innerHeight
      this.imgw = window.innerWidth
    }
  }
}
</script>

<style lang="scss">
  .okaml_background_image .v-image__image--cover {
    opacity: 0.3;
    background-color: #445 !important;
  }
  .okaml_background_image {
    position: absolute;
    background-color: #555 !important;
    height: 100%;
    z-index: 0;
  }
  .okaml_cursor_pointer {
    cursor: pointer
  }
  .theme--light.v-card {
    color: $label
  }
  .v-label {
    top: 0em !important;
  }
  .v-card__title {
    word-break: break-word
  }
  .v-text-field__slot label, .v-select__slot label {
    top: 3px !important;
    font-size: 14px;
  }
  // styles for list transition
  .list-enter-active,
  .list-leave-active,
  .list-move {
    transition: 1000ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
    transition-property: opacity, transform;
  }
  .list-enter {
    opacity: 0;
    transform: translateX(50px) scaleY(0.5);
  }
  .list-enter-to {
    opacity: 1;
    transform: translateX(0) scaleY(1);
  }
  .list-leave-active {
    position: absolute;
  }
  .list-leave-to {
    opacity: 0;
    transform: scaleY(0);
    transform-origin: center top;
  }
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 4px;
  }
  .okaml.theme--light.v-expansion-panels .v-expansion-panel {
    color: rgba(0, 0, 0, 0.6)
  }
.v-btn:not(.v-btn--outlined).primary1,
.v-btn:not(.v-btn--outlined).primary2,
.v-btn:not(.v-btn--outlined).secondary1,
.v-btn:not(.v-btn--outlined).secondary2,
.v-btn:not(.v-btn--outlined).info1,
.v-btn:not(.v-btn--outlined).info2{
    color: #FFFFFF;
}
</style>
