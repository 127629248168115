<template>
  <div>
    <transition
      mode="out-in"
      :css="false"
      @before-enter="beforeEnter"
      @enter="enter"
    >
      <v-card
        v-if="apiInfo !== null"
        class="pa-2 my-2 body-2"
        :color="`${color}--text`"
        outlined
        tile
        style="border-radius: 0.3em;"
      >
        {{ apiInfo }}
        <slot />
      </v-card>
    </transition>
  </div>
</template>

<script>
import Velocity from 'velocity-animate'

export default {
  name: 'ApiInfo',
  props: {
    apiInfo: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: 'error'
    }
  },
  methods: {
    beforeEnter (el) {
      el.style.opacity = 0
    },
    enter (el, done) {
      Velocity(el,
        { opacity: 1 },
        { duration: 400, easing: 'easeIn  ', complete: done })
    }
  }
}
</script>
