import Vue from 'vue'
import { config } from '@/plugins/index/config'
import axios from 'axios'
import store from '@/plugins/store'
import i18n from '@/plugins/i18n'

const xapi = axios.create({
  baseURL: config.api_url,
  headers: {
    'Accept': 'application/json, text/plain, */*'
  }
})

xapi.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${sessionStorage.getItem('token')}`
  config.headers['userlocale'] = store.state.app.locale
  config.headers['caller'] = sessionStorage.getItem('caller')
  return config
}, err => {
  return Promise.reject(err)
})

xapi.interceptors.response.use(function (response) {
  return response
}, function (err) {
  if (err.response) {
    err.response.code = err.response.statusText
    if (err.response.data && err.response.data.code) {
      err.response.code = err.response.data.code
    }
    err.response.localizedMessage = i18n.t(`$l.errorcode.${err.response.code}`)
    if (err.response.data && err.response.data.message) {
      if (err.response.data.message.startsWith('$l.')) {
        err.response.localizedMessage = i18n.t(err.response.data.message)
      }
    }
    if (err.response.status === 401) {
      store.dispatch('logoutUser')
      return Promise.reject(err)
    } else {
      return Promise.reject(err)
    }
  } else {
    err.response = {}
    err.response.code = 'default'
    err.response.status = 404
    err.response.message = i18n.t(`$l.errorcode.${err.response.code}`) + ' Root cause: ' + err.message
    err.response.localizedMessage = i18n.t(`$l.errorcode.${err.response.code}`) + ' Root cause: ' + err.message
    return Promise.reject(err)
  }
})

export default xapi

Vue.prototype.$xapi = xapi
