import sk from './sk-SK.json'
import cs from './cs-CZ.json'
import en from './en-US.json'
import de from './de-DE.json'

export const languages = {
  sk: sk,
  cs: cs,
  en: en,
  de: de
}
