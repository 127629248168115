<template>
  <v-app-bar
    class="primary okaml_v-app-bar"
    app
    dark
  >
    <v-row
      class="ma-0 pa-0 align-center"
      style="display: contents !important"
    >
      <v-btn
        icon
        class="ma-0 pa-0"
        @click.stop="$store.commit('TOGGLE_DRAWER')"
      >
        <v-icon>{{ ($store.state.app.drawer && !$vuetify.breakpoint.xs) ? 'mdi-view-quilt' : 'mdi-menu' }}</v-icon>
      </v-btn>

      <div
        class="logoWrapper okaml_cursor_pointer"
        @click.stop="$router.push('/overview')"
      >
        <v-img
          :src="require('@/assets/okaml.svg')"
          class="logoContent"
          contain
          :height="$vuetify.breakpoint.xs ? 24 : 30"
          :width="$vuetify.breakpoint.xs ? 90 : 120"
        />
      </div>

      <div
        v-if="searchNotFocused()"
        class="mx-2"
        :class="{'text-h6' : !$vuetify.breakpoint.xs}"
        style="line-height: 1.2em;"
      >
        {{ $route.meta.label | translate }}
      </div>

      <SearchAnyPerson
        v-if="this.$route.path !== '/screening'"
        id="screeningSearchItemsMenuAnchor"
        class="mx-2 white"
        style="width: 100%; max-width: 20em; border-radius: 4px; overflow: hidden;"
      />

      <v-spacer />
      <app-order-cart
        v-if="$store.state.user.isAuthenticated && cartItems.length > 0"
        class="mr-4"
        :cart-items="cartItems"
      />

      <app-right-menu
        class="mr-0"
      />
    </v-row>
  </v-app-bar>
</template>

<script>
import SearchAnyPerson from '@/components/screening/SearchAnyPerson.vue'

export default {
  name: 'AppBar',
  components: {
    SearchAnyPerson
  },
  computed: {
    cartItems () {
      if (this.$store.state.app.cartStatus) {} // makes this reactive
      if (sessionStorage.getItem('okaml-cart')) {
        return JSON.parse(sessionStorage.getItem('okaml-cart'))
      } else {
        return []
      }
    }
  },
  methods: {
    searchNotFocused () {
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
  .logoWrapper {
    background-color:white;
    border-radius: 22px;
    padding: 8px;
  }
</style>

<style lang="scss">
  .okaml_v-app-bar .v-toolbar__content {
    padding: 0px;
  }
</style>
