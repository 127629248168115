<template>
  <div class="pa-5">
    <basic-card
      width="400"
      color="primary"
      :title="$i18n.t('$l.app.register')"
      :text="$i18n.t('$l.app.registerBenefit')"
      :text-class="'white--text'"
      title-color="white"
      title-weight="bold"
      title-justify="center"
      class="bgLoginCard pa-0 pb-3 pt-0 mx-auto justify-center text-center"
      text-color="error--text subtitle-2 justify-center text-center"
    >
      <app-api-info
        :api-info="apiError"
        class="pb-3 mx-1"
      />

      <app-api-info
        :api-info="apiInfo"
        color="info1"
        class="pb-3 mx-1"
      />

      <v-form
        lazy-validation
        class="ma-0 pa-0 mt-3"
        @submit.prevent="submit"
      >
        <v-text-field
          ref="regEmail"
          v-model="registration.email"
          autocomplete="new-password"
          :label="$i18n.t('$l.app.email')"
          class="white ma-0 mx-3"
          prepend-icon="mdi-account-circle"
          :error-messages="getEmailErrorMsg($v.registration.email)"
          maxlength="64"
          counter
          @input="$v.registration.email.$touch()"
          @blur="$v.registration.email.$touch()"
        />
        <v-text-field
          v-model="registration.regPassword"
          autocomplete="new-password"
          class="white ma-0 mx-3 mt-4"
          :type="hidenPassword1 ? 'password' : 'text'"
          :append-icon="hidenPassword1 ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
          :label="$i18n.t('$l.app.regPassword')"
          prepend-icon="mdi-lock"
          :error-messages="getPasswordErrorMsq($v.registration.regPassword)"
          maxlength="32"
          counter
          @input="$v.registration.regPassword.$touch()"
          @blur="$v.registration.regPassword.$touch()"
          @click:append="() => (hidenPassword1 = !hidenPassword1)"
        />
        <v-text-field
          v-model="registration.businessId"
          autocomplete="new-password"
          class="white ma-0 mx-3 mt-4"
          prepend-icon="mdi-account"
          :label="$i18n.t('$l.company.businessId')"
          :error-messages="getBusinessIdErrorMsg($v.registration.businessId)"
          @input="$v.registration.businessId.$touch()"
          @blur="$v.registration.businessId.$touch()"
        />
        <v-row class="pa-0 px-6 py-4">
          <v-btn
            class="primary"
            text
            :loading="loadingSubmitButton"
            type="submit"
            block
          >
            {{ $i18n.t('$l.app.createAccount') }}
          </v-btn>
        </v-row>
      </v-form>

      <v-row
        class="ma-0 pa-0 mx-3 align-center"
      >
        <v-col
          cols="1"
          class="ma-0 pa-0"
        >
          <v-checkbox
            v-model="registration.checkedTermsOfUse"
            :error-messages="($v.registration.checkedTermsOfUse.$dirty && !$v.registration.checkedTermsOfUse.sameAs) ? ' ' : ''"
            @input="$v.registration.checkedTermsOfUse.$touch()"
            @blur="$v.registration.checkedTermsOfUse.$touch()"
          />
        </v-col>
        <v-col
          cols="11"
          class="ma-0 pa-0 text-start justify-start pl-2"
        >
          <span class="body-2">{{ $i18n.t('$l.app.withRegistrationAgreeWith') }}
            <a
              href="/terms-of-service"
            >
              {{ $i18n.t('$l.app.termsOfService') }}
            </a>
          </span>
        </v-col>
      </v-row>
    </basic-card>
    <basic-dialog
      v-model="confirmFreemail"
      title="$l.app.warning"
      save-button
      cancel-button
      save-button-label="$l.app.changeEmail"
      cancel-button-label="$l.app.ignore"
      cancel-button-color="error"
      save-button-color="success"
      @close-dialog="confirmFreemail = false"
      @cancel-dialog="ignorwWarning()"
      @save-dialog="deleteEmail()"
    >
      <v-card-title>
        <table>
          <td>
            <v-icon
              large
              color="warning darken-1"
              class="pt-5"
            >
              mdi-alert
            </v-icon>
          </td>
          <td class="pl-2">
            {{ $i18n.t('$l.app.emailWarning') }}
          </td>
        </table>
      </v-card-title>
    </basic-dialog>
  </div>
</template>

<script>
import { required, email, sameAs, numeric } from 'vuelidate/lib/validators'

export default {
  name: 'FormRegister',
  data () {
    return {
      apiError: null,
      apiInfo: null,
      registration: {
        email: '',
        regPassword: '',
        checkedTermsOfUse: false
      },
      isRegistering: false,
      isTestingEmail: false,
      hidenPassword1: true,
      confirmFreemail: false
    }
  },
  computed: {
    loadingSubmitButton () {
      return this.isRegistering || this.isTestingEmail
    }
  },
  validations: {
    registration: {
      email: {
        required,
        email
      },
      regPassword: {
        required,
        strongPassword (password1) {
          return (
            /[a-z]/.test(password1) && // checks for a-z
            /[0-9]/.test(password1) && // checks for 0-9
            /\W|_/.test(password1) && // checks for special char
            password1.length >= 8
          )
        }
      },
      checkedTermsOfUse: {
        sameAs: sameAs(() => true)
      },
      businessId: {
        numeric,
        businessIdLengthConstrain (val) {
          return (val && (val.length === 8)) || val === undefined || val === ''
        }
      }
    }
  },
  mounted () {
    this.$refs.regEmail.focus()
  },
  methods: {
    deleteEmail () {
      this.confirmFreemail = false
      setTimeout(() => {
        this.$refs.regEmail.focus()
        this.$refs.regEmail.select()
      }, 500)
      setTimeout(() => this.$refs.regEmail.select(), 500)
    },
    ignorwWarning () {
      this.confirmFreemail = false
      this.doRegister()
    },
    submit () {
      this.apiError = null
      this.apiInfo = null
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.isTestingEmail = true
      this.$xapi.post('user/isFreemail?email=' + this.registration.email)
        .then(r => {
          if (r.data) {
            this.confirmFreemail = true
          } else {
            this.doRegister()
          }
        })
        .catch(e => {
          this.apiError = e.response.localizedMessage
        })
        .finally(() => {
          this.isTestingEmail = false
        })
    },
    doRegister () {
      this.apiError = null
      this.apiInfo = null
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.isRegistering = true
      this.$store.dispatch('registerUser', this.registration)
        .then((response) => {
          this.apiInfo = this.$i18n.t('$l.info.registrationEmailSent')
        })
        .catch(e => {
          if (e.response.status === 400) {
            this.apiError = this.$i18n.t('$l.error.userAlreadyExists')
          } else {
            this.apiError = this.$i18n.t('$l.error.registrationFailed')
          }
        })
        .finally(() => {
          this.isRegistering = false
        })
    },
    getEmailErrorMsg (email) {
      var message = ''
      if (email.$dirty && !email.required) {
        message = this.$i18n.t('$l.app.required')
      } else if (email.$dirty && !email.email) {
        message = this.$i18n.t('$l.app.validationEmailFormat')
      }
      return message
    },
    getPasswordErrorMsq (regPassword) {
      var message = ''
      if (regPassword.$dirty && !regPassword.required) {
        message = this.$i18n.t('$l.app.required')
      } else if (regPassword.$dirty && !regPassword.strongPassword) {
        message = this.$i18n.t('$l.app.validationStrongPassword')
      }
      return message
    },
    getBusinessIdErrorMsg (businessId) {
      var message = ''
      if (businessId.$dirty && !businessId.numeric) {
        message = this.$i18n.t('$l.app.validationNumeric')
      } else if (businessId.$dirty && !businessId.businessIdLengthConstrain) {
        message = this.$i18n.t('$l.app.validationLength8digits')
      }
      return message
    }
  }
}
</script>

<style lang="scss" scoped>
  .bgLoginCard {
    background: linear-gradient(110deg, $white, $white);
    border-radius: 0.5em;
  }
</style>
