<template>
  <v-snackbar
    v-model="snackbar"
    color="error"
    timeout="-1"
    top
    vertical
  >
    <v-icon
      dark
      class="mr-2"
    >
      mdi-refresh
    </v-icon>
    {{ $i18n.t('$l.app.newVersionAvailable') }}

    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        class="yellow--text"
        @click.stop="refreshPage"
      >
        {{ $i18n.t('$l.api.refresh') }}
      </v-btn>
      <v-btn
        text
        v-bind="attrs"
        @click.stop="snackbar = false"
      >
        {{ $i18n.t('$l.app.cancel') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { config } from '@/plugins/index/config'

export default {
  name: 'VersionChecker',
  data () {
    return {
      snackbar: false
    }
  },
  computed: {
    version () {
      return process.env.PACKAGE_VERSION
    }
  },
  created () {
    this.pushSocket = new WebSocket(config.wss_url + 'push')
    this.pushSocket.onmessage = (event) => {
      if (JSON.parse(event.data).message !== this.version) {
        this.snackbar = true
      }
    }
  },
  methods: {
    refreshPage () {
      location.reload(true)
    }
  }
}
</script>
