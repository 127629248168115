<template>
  <v-list
    class="ma-0 pa-0 overflow-y-auto"
  >
    <template
      v-for="(item, idx) in screeningSearchItems"
    >
      <v-list-item
        v-if="item.name"
        :key="idx"
        class="ma-0 pa-1 px-2"
        color="success"
        two-line
        style="border-bottom: 1px solid #9ba; min-height: auto;"
        :style="{'background-color' : item.ipo ? '#f0ffee' : '#eefeff'}"
        @click="$emit('redirect-to-screening', item)"
      >
        <v-list-item-content
          class="ma-0 pa-0"
        >
          <v-list-item-title
            class="body-2"
          >
            {{ item.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ item.address }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action
          class="body-2 ma-0 pa-0"
        >
          {{ item.ipo }}
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
export default {
  name: 'ScreeningSearchItems',
  props: {
    screeningSearchItems: {
      type: Array,
      required: true
    }
  }
}
</script>
