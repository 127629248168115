import Vue from 'vue'
import i18n from '@/plugins/i18n'
import store from '@/plugins/store'
import { config } from '@/plugins/index/config'

const participantDefault = {
  id: '',
  businessId: '',
  name: '',
  type: '',
  formData: {},
  result: {},
  investigations: [],
  legalRepresentative: false,
  authorizedRepresentative: false
}

function getCurrentYear () {
  return new Date().getFullYear()
}
function getCurrentDate () {
  return new Date().toLocaleString([i18n.locale], { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })
}

function formatDate (stringDate) {
  if (stringDate) {
    stringDate = stringDate.replace('[UTC]', '')
    const date = new Date(stringDate)
    return date.toLocaleString([i18n.locale], { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })
  } else {
    return ''
  }
}

function formatDateToDatePicker (stringDate) {
  if (stringDate) {
    const date = new Date(stringDate)
    var year = date.toLocaleString(['sk'], { year: 'numeric' })
    var month = date.toLocaleString(['sk'], { month: '2-digit' })
    var day = date.toLocaleString(['sk'], { day: '2-digit' })
    var hour = date.toLocaleString(['sk'], { hour: '2-digit' })
    var minute = date.toLocaleString(['sk'], { minute: '2-digit' })
    month = month.replace('.', '')
    day = day.replace('.', '')
    if (minute < 10) {
      minute = '0' + minute
    }

    return year + '-' + month + '-' + day + ' ' + hour + ':' + minute
  } else {
    return ''
  }
}

function formatDateWithoutTime (stringDate) {
  if (stringDate) {
    stringDate = stringDate.replace('Z', '')
    stringDate = stringDate.replace('[UTC]', '')
    const date = new Date(stringDate)
    return date.toLocaleString([i18n.locale], { month: 'short', day: '2-digit', year: 'numeric' })
  } else {
    return ''
  }
}

function formatCountry (countryA2Code) {
  let countries = store.getters.getCountries
  if (countryA2Code) {
    for (var i = 0; i < countries.length; i++) {
      if (countries[i].iso3166_1.toLowerCase() === countryA2Code.toLowerCase()) {
        return countries[i].name
      }
    }
  }
  return countryA2Code
}

function formatCurrency (price) {
  return new Intl.NumberFormat(i18n.locale, { style: 'currency', currency: 'EUR' }).format(price)
}

function formatCurrencyNoZero (price) {
  if (price === 0) {
    return '- €'
  }
  return new Intl.NumberFormat(i18n.locale, { style: 'currency', currency: 'EUR' }).format(price)
}

const parseStatusText = s => {
  let div = document.createElement('div')
  div.innerHTML = s
  return JSON.parse(div.innerText)
}

function numberWithSpaces (x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

const utils = {
  participantDefault: participantDefault,
  getCurrentYear: () => getCurrentYear(),
  getCurrentDate: () => getCurrentDate(),
  formatDate: (stringDate) => formatDate(stringDate),
  formatDateWithoutTime: (stringDate) => formatDateWithoutTime(stringDate),
  formatDateToDatePicker: (stringDate) => formatDateToDatePicker(stringDate),
  formatCountry: (countryA2Code) => formatCountry(countryA2Code),
  formatCurrency: (price) => formatCurrency(price),
  formatCurrencyNoZero: (price) => formatCurrencyNoZero(price),
  numberWithSpaces: (int) => numberWithSpaces(int),
  parseStatusText,
  config
}

export default utils

Vue.prototype.$utils = utils
