<template>
  <div class="pa-5">
    <basic-card
      width="400"
      color="primary"
      :title="$i18n.t('$l.app.signingIn')"
      title-color="white"
      title-weight="bold"
      title-justify="center"
      class="bgLoginCard pa-0 pb-3 pt-0 mx-auto"
      text-color="error--text subtitle-2 text-center"
    >
      <app-api-info
        :api-info="apiError"
        class="pb-3 mx-1"
      />

      <app-api-info
        :api-info="apiInfo"
        color="info1"
        class="pb-3 mx-1"
      />

      <v-form
        ref="loginForm"
        lazy-validation
        :disabled="alertDoesExist()"
        class="ma-0 pa-0"
        @submit.prevent="doSignInBtn"
      >
        <v-text-field
          ref="userName"
          v-model="login.userName"
          :label="$i18n.t('$l.app.userName')"
          class="white ma-0 mx-3 mt-3"
          prepend-icon="mdi-account-circle"
          :error-messages="
            $v.login.userName.$dirty && !$v.login.userName.required
              ? $i18n.t('$l.app.required')
              : ''
          "
          maxlength="64"
          counter
          @input="$v.login.userName.$touch()"
          @blur="$v.login.userName.$touch()"
        />
        <v-text-field
          v-model="login.password"
          class="white ma-0 mx-3 mt-4"
          :type="hidenPassword1 ? 'password' : 'text'"
          :append-icon="
            hidenPassword1 ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
          "
          :label="$i18n.t('$l.app.password')"
          prepend-icon="mdi-lock"
          :error-messages="
            $v.login.password.$dirty && !$v.login.password.required
              ? $i18n.t('$l.app.required')
              : ''
          "
          maxlength="32"
          counter
          @input="$v.login.password.$touch()"
          @blur="$v.login.password.$touch()"
          @click:append="() => (hidenPassword1 = !hidenPassword1)"
        />

        <v-row class="pa-0 px-6 pt-3 pb-4">
          <v-btn
            class="secondary"
            text
            :disabled="alertDoesExist()"
            :loading="isSigningIn"
            type="submit"
            block
          >
            {{ $i18n.t("$l.app.signIn") }}
          </v-btn>
        </v-row>

        <v-row class="ma-0 text-center justify-center">
          {{ $i18n.t("$l.app.or") }}
        </v-row>

        <v-row class="pa-0 px-6 py-3 text-center justify-center">
          <v-btn
            class="primary"
            text
            :disabled="alertDoesExist()"
            @click="$router.push('/').catch((e) => {})"
          >
            {{ $i18n.t("$l.app.toRegister") }}
          </v-btn>
        </v-row>

        <v-row class="ma-0 text-center justify-center">
          <v-btn
            text
            small
            :disabled="alertDoesExist()"
            class="label--text"
            @click.stop="$router.push('/forgotPassword').catch((e) => {})"
          >
            {{ "$l.app.forgotPassword" | translate }}
          </v-btn>
        </v-row>
      </v-form>
    </basic-card>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'FormLogin',
  props: {
    actid: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      apiError: null,
      apiInfo: null,
      alert: null,
      login: {
        userName: '',
        password: ''
      },
      isSigningIn: false,
      hidenPassword1: true
    }
  },
  validations: {
    login: {
      userName: {
        required
      },
      password: {
        required
      }
    }
  },
  created () {
    this.getStatusSystemAlert()
  },
  mounted () {
    this.$refs.userName.focus()
    if (this.actid && this.actid.length > 0) {
      this.doSignIn()
    }
  },
  methods: {
    getStatusSystemAlert () {
      var inHours = 0
      this.apiError = null
      this.$xapi
        .get('administration/activeSystemAlert?inHours=' + inHours)
        .then((alerts) => {
          this.alert = alerts.data
        })
        .catch((e) => {
          if (e.response.status === 503) {
            this.apiError = this.$i18n.t('$l.systemalert.systemAlertCurrentlyInRunning')
          }
        })
        .finally(() => {
          if (this.alert != null) {
            this.apiInfo =
              this.$i18n.t('$l.error.systemShutdownAlert') +
              ' ' +
              this.$utils.formatDate(this.alert.endDate.replace('[UTC]', ''))
          }
        })
    },
    alertDoesExist () {
      if (this.alert) {
        return true
      } else {
        return false
      }
    },
    doSignInBtn () {
      // TODO this is incorrect mutating a prop; to be fixed
      this.actid = ''
      this.doSignIn()
    },

    doSignIn () {
      this.apiError = null
      this.$v.$touch()
      if (this.actid && this.actid.length > 0) {
      } else {
        if (this.$v.$invalid) return
      }
      if (!this.$refs.loginForm.validate()) return
      this.isSigningIn = true
      this.login.actid = this.actid
      this.$store
        .dispatch('loginUser', this.login)
        .then(() => {
          this.$refs.loginForm.reset()
          this.checkSystemBarInfo()
        })
        .catch((e) => {
          switch (e.response.status) {
            case 400:
              this.apiError = this.$i18n.t('$l.error.credentialsMissing')
              break
            case 401:
              this.apiError = this.$i18n.t('$l.error.wrongUserNameOrPassword')
              break
            case 403:
              if (this.actid && this.actid.length > 0) {
                this.apiInfo = this.$i18n.t('$l.error.activationLinkWrongUsedExpired')
              } else {
                if (e.response.statusText === 'Forbidden') {
                  this.apiInfo = this.$i18n.t('$l.error.userNotActivated')
                } else {
                  this.apiError = e.response.localizedMessage
                }
              }
              break
            case 503:
              this.apiError = this.$i18n.t('$l.error.serviceUnavailable')
              break
            default:
              this.apiError = this.$i18n.t('$l.error.loginFailed')
          }
        })
        .finally(() => {
          this.isSigningIn = false
        })
    },
    checkSystemBarInfo () {
      this.$store.commit('SET_SYSTEM_BAR_INFO', '')
    }
  }
}
</script>

<style lang="scss" scoped>
  .bgLoginCard {
    background: linear-gradient(110deg, $white, $white);
    border-radius: 0.5em;
  }
</style>
