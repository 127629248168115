<template>
  <div class="pa-5">
    <basic-card
      width="400"
      color="primary"
      :title="$i18n.t('$l.app.forgotPassword')"
      title-color="white"
      title-weight="bold"
      title-justify="center"
      offset="36"
      class="bgLoginCard pa-4 pb-3 pt-0 mx-auto"
    >
      <app-api-info
        :api-info="apiError"
        class="pb-3 mx-1"
      />

      <app-api-info
        :api-info="apiInfo"
        color="info1"
        class="pb-3 mx-1"
      />

      <v-card-text class="ma-0 pa-0">
        <v-form
          ref="forgotPasswordForm"
          lazy-validation
          @submit.prevent="submitForm"
        >
          <v-text-field
            ref="e-mail"
            v-model="forgotPassword.email"
            :label="'$l.app.email' | translate"
            class="my-3"
            :error-messages="getEmailErrorMsg($v.forgotPassword.email)"
            maxlength="64"
            counter
            @input="$v.forgotPassword.email.$touch()"
            @blur="$v.forgotPassword.email.$touch()"
          />
          <v-btn
            class="primary"
            type="submit"
            block
            :loading="formIsSending"
          >
            {{ '$l.app.requestPasswordReset' | translate }}
          </v-btn>
        </v-form>
      </v-card-text>
    </basic-card>
    <basic-dialog
      v-model="infoDialog"
      title=""
      save-button
      save-button-label="$l.app.ok"
      @close-dialog="closeInfoDialog"
      @save-dialog="closeInfoDialog"
    >
      <v-card-title>
        {{ '$l.app.enterEmailForPasswordChange' | translate }}
      </v-card-title>
    </basic-dialog>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'FormForgot',
  data () {
    return {
      infoDialog: false,
      formIsSending: false,
      forgotPassword: {
        email: null
      },
      apiError: null,
      apiInfo: null
    }
  },
  validations: {
    forgotPassword: {
      email: {
        required,
        email
      }
    }
  },
  methods: {
    submitForm () {
      this.apiError = null
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.formIsSending = true
      this.$xapi.post(`user/forgotPassword?email=${this.forgotPassword.email}`)
        .then(response => {
          this.infoDialog = true
          this.apiInfo = this.$i18n.t('$l.app.enterEmailForPasswordChange')
        })
        .catch(e => {
          this.apiError = e.response.localizedMessage
        })
        .finally(() => {
          this.formIsSending = false
        })
    },
    closeInfoDialog () {
      this.infoDialog = false
      this.$router.push('/sign-in/1')
    },
    getEmailErrorMsg (email) {
      var message = ''
      if (email.$dirty && !email.required) {
        message = this.$i18n.t('$l.app.required')
      } else if (email.$dirty && !email.email) {
        message = this.$i18n.t('$l.app.validationEmailFormat')
      }
      return message
    }
  }
}
</script>
