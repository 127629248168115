<template>
  <div class="pa-5">
    <basic-card
      width="400"
      color="primary"
      :title="$i18n.t('$l.app.resetPassword')"
      title-color="white"
      title-weight="bold"
      title-justify="center"
      offset="36"
      class="bgLoginCard pa-4 pb-3 pt-0 mx-auto"
    >
      <app-api-info
        :api-info="apiError"
        class="pb-3 mx-1"
      />

      <app-api-info
        :api-info="apiInfo"
        color="info1"
        class="pb-3 mx-1"
      />
      <v-card-title class="justify-center ma-0 pa-0 mb-5 card-title">
        {{ '$l.app.resetPasswordCondition' | translate }}
      </v-card-title>

      <v-card-text class="ma-0 pa-0">
        <v-form
          ref="forgotPasswordForm"
          lazy-validation
          @submit.prevent="submitForm"
        >
          <v-text-field
            v-model="forgotPassword.regPassword"
            class="okaml_white_label my-3"
            :type="hidenPassword1 ? 'password' : 'text'"
            :append-icon="hidenPassword1 ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
            :label="'$l.app.regPassword' | translate"
            prepend-icon="mdi-lock"
            :error-messages="getPasswordErrorMsq($v.forgotPassword.regPassword)"
            maxlength="32"
            counter
            @input="$v.forgotPassword.regPassword.$touch()"
            @blur="$v.forgotPassword.regPassword.$touch()"
            @click:append="() => (hidenPassword1 = !hidenPassword1)"
          />
          <v-btn
            class="primary"
            type="submit"
            block
            :loading="formIsSending"
          >
            {{ '$l.app.confirm' | translate }}
          </v-btn>
        </v-form>
      </v-card-text>
    </basic-card>

    <basic-dialog
      v-model="infoDialog"
      title=""
      save-button
      save-button-label="$l.app.ok"
      @close-dialog="closeInfoDialog"
      @save-dialog="closeInfoDialog"
    >
      <v-card-title>
        {{ '$l.app.passwordChanged' | translate }}
      </v-card-title>
    </basic-dialog>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'FormReset',
  data () {
    return {
      infoDialog: false,
      formIsSending: false,
      hidenPassword1: true,
      forgotPassword: {
        email: null
      },
      apiError: null,
      apiInfo: null
    }
  },
  validations: {
    forgotPassword: {
      regPassword: {
        required,
        strongPassword (password1) {
          return (
            /[a-z]/.test(password1) && // checks for a-z
            /[0-9]/.test(password1) && // checks for 0-9
            /\W|_/.test(password1) && // checks for special char
            password1.length >= 8
          )
        }
      }
    }
  },
  methods: {
    submitForm () {
      this.apiError = null
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.formIsSending = true
      return this.$xapi.post('user/resetPassword',
        { password: this.forgotPassword.regPassword,
          resetPasswordHash: this.$route.params.tokenHash
        })
        .then(response => {
          this.infoDialog = true
          this.apiInfo = this.$i18n.t('$l.app.passwordChanged')
        })
        .catch(e => {
          if (e.response.status === 400) {
            this.apiError = this.$i18n.t('$l.error.wrongActivationLink')
          } else if (e.response.status === 403) {
            this.apiError = this.$i18n.t('$l.error.activationLinkExpired')
          } else {
            this.apiError = e.response.localizedMessage
          }
        })
        .finally(() => {
          this.formIsSending = false
        })
    },
    closeInfoDialog () {
      this.infoDialog = false
      this.$router.push('/sign-in/1')
    },
    getPasswordErrorMsq (regPassword) {
      var message = ''
      if (regPassword.$dirty && !regPassword.required) {
        message = this.$i18n.t('$l.app.required')
      } else if (regPassword.$dirty && !regPassword.strongPassword) {
        message = this.$i18n.t('$l.app.validationStrongPassword')
      }
      return message
    }
  }
}
</script>
