export default [
  // PATHS FOR MAIN MENU ITEMS
  {
    path: '/overview',
    name: 'Overview',
    component: () => ({ component: import(/* webpackChunkName: "Overview" */ '@/views/Overview.vue') }),
    meta: {
      requiresAuth: true,
      label: '$l.paths.overview',
      icon: 'mdi-monitor-dashboard',
      isMainMenuItem: true
    }
  },
  {
    path: '/services',
    name: 'Services',
    component: () => ({ component: import(/* webpackChunkName: "Services" */ '@/views/Services.vue') }),
    meta: {
      requiresAuth: true,
      label: '$l.paths.services',
      icon: 'mdi-shopping-outline',
      isMainMenuItem: true
    }
  },
  {
    path: '/investigation/transaction',
    name: 'Investigation',
    component: () => ({ component: import(/* webpackChunkName: "Investigation" */ '@/views/Investigation.vue') }),
    meta: {
      requiresAuth: true,
      label: '$l.paths.investigation',
      icon: 'mdi-account-search-outline',
      isMainMenuItem: true
    }
  },
  {
    path: '/investigation/person',
    name: 'InvestigationPerson',
    component: () => ({ component: import(/* webpackChunkName: "InvestigationPerson" */ '@/views/InvestigationPerson.vue') }),
    meta: {
      requiresAuth: true,
      label: '$l.paths.investigationPerson',
      icon: 'mdi-account-search-outline',
      isMainMenuItem: true
    }
  },
  {
    path: '/archive',
    name: 'Archive',
    component: () => ({ component: import(/* webpackChunkName: "Archive" */ '@/views/Archive.vue') }),
    meta: {
      requiresAuth: true,
      label: '$l.paths.archive',
      icon: 'mdi-archive-arrow-down-outline',
      isMainMenuItem: true
    }
  },
  {
    path: '/screening',
    name: 'Screening',
    component: () => ({ component: import(/* webpackChunkName: "Screening" */ '@/views/Screening.vue') }),
    meta: {
      requiresAuth: true,
      label: '$l.paths.screening',
      icon: 'mdi-account-search-outline',
      isMainMenuItem: true
    }
  },
  {
    path: '/apiInvestigation',
    name: 'ApiInvestigation',
    component: () => ({ component: import(/* webpackChunkName: "ApiInvestigation" */ '@/views/ApiInvestigation.vue') }),
    meta: {
      requiresAuth: true,
      label: '$l.paths.apiInvestigation',
      icon: 'mdi-account-search-outline',
      isMainMenuItem: true
    }
  },
  {
    path: '/apiSubjectCard',
    name: 'ApiSubjectCardList',
    component: () => ({ component: import(/* webpackChunkName: "ApiSubjectCardList" */ '@/views/ApiSubjectCardList.vue') }),
    meta: {
      requiresAuth: true,
      label: '$l.paths.apiSubjectCardList',
      icon: 'mdi-file-document-outline',
      isMainMenuItem: true
    }
  },
  {
    path: '/program',
    name: 'Program',
    component: () => ({ component: import(/* webpackChunkName: "Program" */ '@/views/Program.vue') }),
    meta: {
      requiresAuth: true,
      label: '$l.paths.program',
      icon: 'mdi-file-document-outline',
      isMainMenuItem: true
    }
  },
  // {
  //   path: '/education',
  //   name: 'Education',
  //   component: () => ({ component: import(/* webpackChunkName: "Education" */ '@/views/Education.vue') }),
  //   meta: {
  //     requiresAuth: true,
  //     label: '$l.paths.education',
  //     icon: 'mdi-teach',
  //     isMainMenuItem: false
  //   }
  // },
  {
    path: '/examination',
    name: 'Examination',
    component: () => ({ component: import(/* webpackChunkName: "Examination" */ '@/views/Examination.vue') }),
    meta: {
      requiresAuth: true,
      label: '$l.paths.examination',
      icon: 'mdi-teach',
      isMainMenuItem: false
    }
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => ({ component: import(/* webpackChunkName: "Feedback" */ '@/views/Feedback.vue') }),
    meta: {
      requiresAuth: true,
      label: '$l.paths.feedback',
      icon: 'mdi-comment-arrow-left-outline',
      isMainMenuItem: true
    }
  },
  {
    path: '/multimedia',
    name: 'Multimedia',
    component: () => ({ component: import(/* webpackChunkName: "Multimedia" */ '@/views/Multimedia.vue') }),
    meta: {
      requiresAuth: true,
      label: '$l.paths.multimedia',
      icon: 'mdi-youtube',
      isMainMenuItem: true
    }
  },
  {
    path: '/administration',
    name: 'Administration',
    component: () => ({ component: import(/* webpackChunkName: "Administration" */ '@/views/Administration.vue') }),
    meta: {
      requiresAuth: true,
      label: '$l.paths.administration',
      icon: 'mdi-cog-outline'
    }
  },
  // PATHS FOR USER SETTINGS ITEMS
  {
    path: '/profile',
    name: 'Profile',
    component: () => ({ component: import(/* webpackChunkName: "Profile" */ '@/views/Profile.vue') }),
    meta: {
      requiresAuth: true,
      label: '$l.paths.profile',
      icon: 'mdi-account-circle-outline'
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => ({ component: import(/* webpackChunkName: "Settings" */ '@/views/Settings.vue') }),
    meta: {
      requiresAuth: true,
      label: '$l.paths.settings',
      icon: 'mdi-settings'
    }
  },
  // PATHS FOR UNKNOWN, REGISTER AND LOGIN
  {
    path: '*',
    redirect: {
      name: 'Overview'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => ({ component: import(/* webpackChunkName: "Login" */ '@/views/Login.vue') }),
    meta: {
      requiresAuth: false,
      label: '$l.paths.login'
    }
  },
  {
    path: '/login/actid/:actid',
    name: 'LoginAct',
    component: () => ({ component: import(/* webpackChunkName: "Login" */ '@/views/Login.vue') }),
    meta: {
      requiresAuth: false,
      label: '$l.paths.login'
    }
  },
  {
    path: '/',
    name: 'Register',
    component: () => ({ component: import(/* webpackChunkName: "Register" */ '@/views/Register.vue') }),
    meta: {
      requiresAuth: false,
      label: '$l.paths.register'
    }
  },
  {
    path: '/forgotPassword',
    name: 'ForgotPassword',
    component: () => ({ component: import(/* webpackChunkName: "ForgotPassword" */ '@/views/ForgotPassword.vue') }),
    meta: {
      requiresAuth: false,
      label: '$l.paths.forgotPassword'
    }
  },
  {
    path: '/resetPassword/:tokenHash',
    name: 'ResetPassword',
    component: () => ({ component: import(/* webpackChunkName: "ResetPassword" */ '@/views/ResetPassword.vue') }),
    meta: {
      requiresAuth: false,
      label: '$l.paths.resetPassword'
    }
  },
  {
    path: '/notAuthorized/:from',
    name: 'NotAuthorized',
    component: () => ({ component: import(/* webpackChunkName: "NotAuthorized" */ '@/views/NotAuthorized.vue') }),
    meta: {
      requiresAuth: true,
      label: '$l.paths.notAuthorized'
    }
  },
  // PATHS FOR SUBVIEWS
  {
    path: '/services/:category',
    name: 'ServicesByCategory',
    component: () => ({ component: import(/* webpackChunkName: "Services" */ '@/views/Services.vue') }),
    meta: {
      requiresAuth: true,
      label: '$l.paths.services'
    }
  },
  {
    path: '/quiz/:quizId',
    name: 'Quiz',
    component: () => ({ component: import(/* webpackChunkName: "Quiz" */ '@/views/Quiz.vue') }),
    meta: {
      requiresAuth: true,
      label: 'Quiz',
      icon: 'mdi-shopping-outline',
      isMainMenuItem: false
    }
  },
  {
    path: '/apiCheckList',
    name: 'ApiCheckList',
    component: () => ({ component: import(/* webpackChunkName: "ApiCheckList" */ '@/views/ApiCheckList.vue') }),
    meta: {
      requiresAuth: false,
      label: '$l.paths.apiCheckList'
    }
  },
  {
    path: '/apiSubjectCard/:cardId',
    name: 'ApiSubjectCard',
    component: () => ({ component: import(/* webpackChunkName: "ApiSubjectCard" */ '@/views/ApiSubjectCard.vue') }),
    meta: {
      requiresAuth: true,
      label: '$l.paths.apiSubjectCard'
    }
  },
  // OTHER PATHS
  {
    path: '/processOrder',
    name: 'ProcessOrder',
    component: () => ({ component: import(/* webpackChunkName: "ProcessOrder" */ '@/views/ProcessOrder.vue') }),
    meta: {
      requiresAuth: true,
      label: '$l.paths.processOrder'
    }
  },
  {
    path: '/productCheckout/:pseudoproductId',
    name: 'ProductCheckout',
    component: () => ({ component: import(/* webpackChunkName: "ProductCheckout" */ '@/views/ProductCheckout.vue') }),
    meta: {
      requiresAuth: true,
      label: '$l.paths.ProductCheckout',
      icon: 'mdi-shopping-outline',
      isMainMenuItem: false
    }
  },
  {
    path: '/terms-of-service',
    name: 'TermsOfService',
    component: () => ({ component: import(/* webpackChunkName: "TermsOfService" */ '@/views/TermsOfService.vue') }),
    meta: {
      requiresAuth: false,
      label: '$l.paths.termsOfService'
    }
  },
  {
    path: '/use-of-cookies',
    name: 'UseOfCookies',
    component: () => ({ component: import(/* webpackChunkName: "UseOfCookies" */ '@/views/UseOfCookies.vue') }),
    meta: {
      requiresAuth: false,
      label: '$l.paths.useOfCookies'
    }
  },
  // INVESTIGATION & CASE PATHS
  {
    path: '/investigation/transaction/createCase/:caseType',
    name: 'CreateCase',
    component: () => ({ component: import(/* webpackChunkName: "CreateCase" */ '@/views/investigation/CreateCase.vue') }),
    meta: {
      requiresAuth: true,
      label: '$l.paths.createCase'
    }
  },
  {
    path: '/investigation/person/createCase/:caseType',
    name: 'CreateCasePerson',
    component: () => ({ component: import(/* webpackChunkName: "CreateCase" */ '@/views/investigation/CreateCase.vue') }),
    meta: {
      requiresAuth: true,
      label: '$l.paths.createCase'
    }
  },
  {
    path: '/investigation/transaction/processCase/:caseId',
    name: 'ProcessCase',
    component: () => ({ component: import(/* webpackChunkName: "ProcessCase" */ '@/views/investigation/ProcessCase.vue') }),
    meta: {
      requiresAuth: true,
      label: '$l.paths.processCase'
    }
  },
  {
    path: '/investigation/person/processCase/:caseId',
    name: 'ProcessCasePerson',
    component: () => ({ component: import(/* webpackChunkName: "ProcessCase" */ '@/views/investigation/ProcessCase.vue') }),
    meta: {
      requiresAuth: true,
      label: '$l.paths.processCase'
    }
  },
  {
    path: '/investigation/transaction/resolveCase/:caseId',
    name: 'ResolveCase',
    component: () => ({ component: import(/* webpackChunkName: "ResolveCase" */ '@/views/investigation/ResolveCase.vue') }),
    meta: {
      requiresAuth: true,
      label: '$l.paths.resolveCase'
    }
  },
  {
    path: '/investigation/person/resolveCase/:caseId',
    name: 'ResolveCasePerson',
    component: () => ({ component: import(/* webpackChunkName: "ResolveCase" */ '@/views/investigation/ResolveCase.vue') }),
    meta: {
      requiresAuth: true,
      label: '$l.paths.resolveCase'
    }
  }
]
